import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-onboarding-card',
  templateUrl: './onboarding-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingCardComponent {
  @Input() onboardingSlide: any;
  @Output() inView = new EventEmitter<any>();
  constructor() {}
}
