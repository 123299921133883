import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSlides from '../reducers/onboarding-slides.reducer';
import { OnboardingSlide } from '../../models';
import * as fromRoot from '../../../store';

export const getOnboardingSlideState = createSelector(fromFeature.getOnboardingState, (state: fromFeature.OnboardingState) => state.slides);

export const getOnboardingSlideEntities = createSelector(getOnboardingSlideState, fromSlides.getOnboardingSlidesEntities);

export const getAllOnboardingSlides = createSelector(getOnboardingSlideEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedOnboardingSlides = createSelector(getAllOnboardingSlides, (Pages: OnboardingSlide[]) => {
  const orderedPages = Pages.sort((a, b) => a.order - b.order);
  return orderedPages;
});

export const getOnboardingSlidesLoaded = createSelector(getOnboardingSlideState, fromSlides.getOnboardingSlidesLoaded);
export const getOnboardingSlidesLoading = createSelector(getOnboardingSlideState, fromSlides.getOnboardingSlidesLoading);
