import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as contentblockActions from '../actions/onboarding-slides.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { OnboardingService } from '../../services';

@Injectable()
export class OnboardingSlidesEffects {
  constructor(private actions$: Actions, private service: OnboardingService) {}

  LoadSlides$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentblockActions.LoadOnboardingSlides),
      switchMap(() =>
        this.service.getOnboardingSlides().pipe(
          map(slides => contentblockActions.LoadOnboardingSlidesSuccess({ slides })),
          catchError(error => of(contentblockActions.LoadOnboardingSlidesFail({ error })))
        )
      )
    );
  });
}
