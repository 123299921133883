<div class="card onboarding-card">
  <article class="py-4">
    <h1 class="h3 text-center mb-3">{{ onboardingSlide.title }}</h1>

    <figure class="onboarding-card__figure mb-2">
      <img [src]="onboardingSlide.img | resize : 900" *ngIf="onboardingSlide.img" />
    </figure>
    <main class="onboarding-card__main">
      <p class="text-primary">{{ onboardingSlide.text }}</p>
    </main>
  </article>
  <ng-content></ng-content>
</div>
