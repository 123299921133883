<div class="onboarding-slider">
  <ngx-glide
    #NgxGlideComponent
    [listenToEvents]="true"
    [showArrows]="false"
    [rewind]="false"
    [showBullets]="true"
    [gap]="30"
    [perView]="1"
    (ranBefore)="onSwipeEnd($event)"
    (moved)="updatePage()"
  >
    <app-onboarding-card class="onboarding-slider__item p-3" *ngFor="let slide of slides" [onboardingSlide]="slide">
      <button class="button button--primary" (click)="next()">{{ 'onboarding-slider-volgende-knop-tekst' | fromDictionary }}</button>
    </app-onboarding-card>
  </ngx-glide>
</div>
<footer class="d-flex">
  <button class="button button-link button--primary button--md mt-4 mx-auto text-decoration-underline" (click)="close()">
    <span>Sla onboarding over</span>
  </button>
</footer>
