import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { tap, map, withLatestFrom, filter, take, switchMap, catchError } from 'rxjs/operators';
import * as fromStore from '../store';

import { Observable, of } from 'rxjs';
import { Go } from '../../store';
import { Store } from '@ngrx/store';

export const NeedsOnboardingGuard: CanActivateFn = () => {
  const store = inject(Store);
  return store
    .select(fromStore.getOnboardingSlidesLoaded)
    .pipe(
      withLatestFrom(store.select(fromStore.getAllOnboardingSlides)),
      tap(([loaded, slides]) => {
        if (!loaded) {
          store.dispatch(fromStore.LoadOnboardingSlides());
        }
        if (loaded) {
          const needsOnboarding = window.localStorage.getItem('ONBOARDING_COMPLETE') !== '1';

          // Check if there are any published slides
          if (needsOnboarding && slides.length > 0) {
            store.dispatch(Go({ path: ['/', 'onboarding'] }));
          }
        }
      }),
      filter(([loaded]) => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
