import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DebugService } from '../../services/debug.service';

@Component({
  selector: 'app-debug-pane',
  templateUrl: './debug-pane.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugPaneComponent {
  @Input() title: string = 'Debug pane';
  //@Input() status: 'warning' | 'danger' | 'success' | 'loading' | 'unknown' = 'unknown';
  @Input() statusLabel: string = 'Status:';
  @Input() status: {
    danger?: boolean;
    success?: boolean;
    warning?: boolean;
    loading?: boolean;
    [key: string]: boolean | undefined;
  } = {
    danger: false,
    success: false,
    warning: false,
    loading: false,
  };

  @Output() onToggle = new EventEmitter<boolean>();

  collapsed = true;

  debugService = inject(DebugService);
  debugEnabled$ = this.debugService.getDebugMode();

  get statusLabels(): { status: boolean | undefined; label: string }[] {
    return Object.keys(this.status)
      .map(key => ({ status: this.status[key], label: key }))
      .filter(a => a.status);
  }

  clearLocalstorage() {
    localStorage.clear();
  }
}
