<div class="topbar" [class.set--fixed]="fixed">
  <div class="container">
    <h1 class="topbar__logo">
      <a [routerLink]="'/'">
        <div class="topbar__logo__title">LOGO</div>
      </a>
    </h1>
    <!-- <button class="topbar__nav-toggle" [class.is--active]="menuActive" (click)="toggle.emit()">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
    </button> -->
    <nav class="topbar__nav">
      <ul class="topbar__nav__list">
        <li class="topbar__nav__list__item" *ngFor="let page of menu">
          <a
            (click)="toggleOverlay.emit()"
            [routerLink]="page.url"
            [routerLinkActive]="'is--current'"
            [routerLinkActiveOptions]="{ exact: page.url === '/' }"
          >
            {{ page.title }}
          </a>
        </li>

        <!-- Tijdelijk onboarding vast item -->
        <li class="topbar__nav__list__item">
          <a (click)="toggleOverlay.emit()" [routerLink]="['/onboarding']" [routerLinkActive]="'is--current'">Onboarding</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
