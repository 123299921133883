<section class="onboarding section-inset-y">
  <div class="onboarding__wrapper py-5 container px-0" *ngIf="prevSessionIndex !== null">
    <app-onboarding-slider
      *ngIf="slides$ | async as slides"
      [activeIndex]="prevSessionIndex"
      [slides]="slides"
      (finish)="finish()"
      (back)="back()"
    ></app-onboarding-slider>
    <p *ngIf="!(slides$ | async)?.length">Geen onboarding slides gevuld..</p>
  </div>
</section>
