import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundComponent {
  @Input() playScene: boolean = false;
  @Input() delay: number = 1000;
  @Input() isEndScene: boolean = false;
}
