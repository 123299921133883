import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  route = inject(ActivatedRoute);

  getDebugMode(): Observable<boolean> {
    return this.route.queryParamMap.pipe(map((params: ParamMap) => params.get('debug') === 'true'));
  }
}
