import * as fromSlides from '../reducers/onboarding-slides.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'onboarding';

export interface OnboardingState {
  slides: fromSlides.OnboardingSlidesState;
}

export const reducers: ActionReducerMap<OnboardingState> = {
  slides: fromSlides.OnboardingSlidesReducer,
};

export const getOnboardingState = createFeatureSelector<OnboardingState>(featureKey);

export const metaReducers: MetaReducer<OnboardingState>[] = !environment.production ? [] : [];
