import { createReducer, on } from '@ngrx/store';
import { OnboardingSlide } from '../../models';
import * as pageActions from '../actions/onboarding-slides.action';
import * as utility from '@teamfoster/sdk/array-utility';

export interface OnboardingSlidesState {
  entities: { [id: number]: OnboardingSlide };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: OnboardingSlidesState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const OnboardingSlidesReducer = createReducer(
  initialState,
  on(pageActions.LoadOnboardingSlides, (state: OnboardingSlidesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadOnboardingSlidesSuccess, (state: OnboardingSlidesState, { slides }) => {
    const entities = utility.ToEntities(slides, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadOnboardingSlidesFail, (state: OnboardingSlidesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getOnboardingSlidesEntities = (state: OnboardingSlidesState) => state.entities;
export const getOnboardingSlidesLoading = (state: OnboardingSlidesState) => state.loading;
export const getOnboardingSlidesLoaded = (state: OnboardingSlidesState) => state.loaded;
