import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingComponent } from './containers/onboarding/onboarding.component';
import { OnboardingSliderComponent } from './components/onboarding-slider/onboarding-slider.component';
import { OnboardingCardComponent } from './components/onboarding-card/onboarding-card.component';
import { CoreModule } from '../core/core.module';
import { NgxGlideModule } from 'ngx-glide';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { ImageModule } from '@teamfoster/sdk/image';
import { IconModule } from '@teamfoster/sdk/icon';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromStore from './store';
@NgModule({
  declarations: [OnboardingComponent, OnboardingSliderComponent, OnboardingCardComponent],
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    CoreModule,
    NgxGlideModule,
    DictionaryNgrxModule,
    TextUtilityModule,
    CookieNgrxModule,
    ImageModule,
    IconModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
})
export class OnboardingModule {}
