<ng-container *ngIf="(debugEnabled$ | async) === true">
  <article class="debug-pane">
    <header class="debug-pane__header" (click)="collapsed = !collapsed; onToggle.emit(!collapsed)">
      <strong>
        {{ title }}
        <span class="d-inline-block ms-2">
          <span class="fw-normal">{{ statusLabel }}</span>
          <span class="chip chip--label ms-2" [ngClass]="'chip--' + status.label" *ngFor="let status of statusLabels">
            {{ status.label }}
          </span>
          <span class="chip chip--label ms-2" *ngIf="!statusLabels.length">unknown</span>
        </span>
      </strong>
    </header>

    <main class="debug-pane__content" [class.is--open]="!collapsed">
      <div class="debug-pane__content__inner">
        <ng-content></ng-content>

        <button class="button button--xs button--danger" (click)="clearLocalstorage()">Clear localstorage</button>
      </div>
    </main>
  </article>
</ng-container>
